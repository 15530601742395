header {
    &.topbar {
        background: #FFFFFF;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.07);
        .top-navbar {
            .navbar-header {
                position: relative;
                border-right: none;
                &:before {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 40px;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    color: #D3D3D3;
                    background-color: #D3D3D3;
                }
                .navbar-brand {
                    .logo-icon {
                        justify-content: center;
                        margin-right: 0;
                        img {
                            max-height: 70px !important;
                        }

                        .dark-logo {
                            width: 186px;
                            height: 48px;
                        }
                    }
                }
            }
            .navbar-collapse {
                .pr-name {
                    font-family: 'Arial';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 23px;
                    color: #96AD3D;
                }
                .navbar-nav {
                    margin-left: 29px;
                }
            }
        }
    }
}

@media (max-width: 766px) {
    header {
        .btn-circle {
            width: 30px;
            height: 30px;
            font-size: 13px;
        }
        .navbar-header {
            width: auto !important;
        }
        .navbar-brand {
            padding-left: 0 !important;
            width: auto !important;
            padding-right: 0px !important;
        }
        .top-navbar {
            flex-wrap: initial;
        }
        .navbar-collapse {
            display: flex !important;
            padding-right: 0px !important;
            .navbar-nav {
                margin-left: 10px !important;
                .pr-name {
                    white-space: nowrap;
                }
                .nav-link {
                    padding-left: 0px !important;
                    padding-right: 10px !important;
                }
            }

        }
        .logo-icon {
            width: 148px !important;
            height: 39px !important;
            object-fit: cover;
            img {
                display: none;
                &.light-logo-mobile {
                    display: block !important;

                }
            }
        }
    }
}

@media (max-width: 320px) {
    header {
        .navbar-collapse {
            .navbar-nav {
                .pr-name {
                    white-space: nowrap;
                    font-size: 13px !important;
                }
            }
        }
    }
}