footer {
    &.footer-bottom {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #B4B3B3;
        //position: fixed;
        bottom: 15px;
        left: 50%;
        //transform: translateX(-50%);
        width: 100%;
        text-align: center;
    }
}
.pr-1 {
    padding-right: 1em;
}
.select2-common{
    min-width: 196px;
    >div {
        width: 100%;
    }
    .css-1s2u09g-control {
        border: none;
        border-bottom: 2px solid #000;
        border-radius: 0;
    }
    .css-1hb7zxy-IndicatorsContainer {
        .css-1okebmr-indicatorSeparator {
            display: none;
        }
        .css-tlfecz-indicatorContainer {
            padding-right: 0;
        }
        .css-1gtu0rj-indicatorContainer {
            padding-right: 0;
        }
    }
    .css-qc6sy-singleValue {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #333333;
    }
}

.preloader {
    .lds-ripple {
        left: 50% !important;
        top:50% !important;
        transform: translate(-50%,-50%);
    }
}

@media (max-width: 766px) {
    footer {
        display: none;
    }
}
.pl-0 {
    padding-left: 0 !important;
}