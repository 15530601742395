// In this scss you can overwrite all our scss, we suggested you to add your scss into this, so whenever the update comes it will not affect your code.

body.no-sidebar{
    font-size: 12px;

    .page-wrapper{
        margin-left: 0 !important;
    }
    .navbar-brand, .logo-icon{
        overflow: hidden;
        height: 70px;
        width: 230px;
    }
    .logo-icon img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    .release-header{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .release-header .qrcode{
        width: 120px;
        height: 120px;
        overflow: hidden;
        margin-right: 30px;
    }
    .release-header .qrcode svg{
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    .release-header .release-name {
        width: calc(100% - 320px);
    }


    .release-header .release-name h4{
        font-weight: bold;
        font-size: 24px;
    }

    .release-header .button{
        width: 200px;

    }
    .release-header .button .btn{
        width: 100%;
    }

    #pagination{
        margin-top: 50px
    }
    .page-link{
        cursor: pointer;
        color: #39cb7f;
    }
    .page-item.active .page-link {
        z-index: 3;
        color: #fff;
        background-color: #39cb7f;
        border-color: #39cb7f;
    }

    @media only screen and (max-width: 480px) {
        .release-header{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: flex-start;
            align-items: center;
        }
        .release-header .qrcode{
            width: 120px;
            height: 120px;
            overflow: hidden;
            /*margin-right: 30px;*/
            margin: auto;
            margin-bottom: 30px;
        }
        .release-header .release-name {
            width: 100%;
        }
        .release-header .release-name h4{
            font-weight: bold;
            font-size: 24px;
            text-align: center;
        }

        .release-header .button{
            width: 100%;

        }
    }
}


/*******************
Padding margin property 
*******************/
html[dir="ltr"] body .m-t-5 {
  margin-top: 5px; }

html[dir="ltr"] body .m-b-5 {
  margin-bottom: 5px; }

html[dir="ltr"] body .m-r-5 {
  margin-right: 5px; }

html[dir="ltr"] body .m-l-5 {
  margin-left: 5px; }

html[dir="ltr"] body .p-t-5 {
  padding-top: 5px; }

html[dir="ltr"] body .p-b-5 {
  padding-bottom: 5px; }

html[dir="ltr"] body .p-r-5 {
  padding-right: 5px; }

html[dir="ltr"] body .p-l-5 {
  padding-left: 5px; }

html[dir="ltr"] body .p-5 {
  padding: 5px; }

html[dir="ltr"] body .m-5 {
  margin: 5px; }

html[dir="ltr"] body .m-t-10 {
  margin-top: 10px; }

html[dir="ltr"] body .m-b-10 {
  margin-bottom: 10px; }

html[dir="ltr"] body .m-r-10 {
  margin-right: 10px; }

html[dir="ltr"] body .m-l-10 {
  margin-left: 10px; }

html[dir="ltr"] body .p-t-10 {
  padding-top: 10px; }

html[dir="ltr"] body .p-b-10 {
  padding-bottom: 10px; }

html[dir="ltr"] body .p-r-10 {
  padding-right: 10px; }

html[dir="ltr"] body .p-l-10 {
  padding-left: 10px; }

html[dir="ltr"] body .p-10 {
  padding: 10px; }

html[dir="ltr"] body .m-10 {
  margin: 10px; }

html[dir="ltr"] body .m-t-15 {
  margin-top: 15px; }

html[dir="ltr"] body .m-b-15 {
  margin-bottom: 15px; }

html[dir="ltr"] body .m-r-15 {
  margin-right: 15px; }

html[dir="ltr"] body .m-l-15 {
  margin-left: 15px; }

html[dir="ltr"] body .p-t-15 {
  padding-top: 15px; }

html[dir="ltr"] body .p-b-15 {
  padding-bottom: 15px; }

html[dir="ltr"] body .p-r-15 {
  padding-right: 15px; }

html[dir="ltr"] body .p-l-15 {
  padding-left: 15px; }

html[dir="ltr"] body .p-15 {
  padding: 15px; }

html[dir="ltr"] body .m-15 {
  margin: 15px; }

html[dir="ltr"] body .m-t-20 {
  margin-top: 20px; }

html[dir="ltr"] body .m-b-20 {
  margin-bottom: 20px; }

html[dir="ltr"] body .m-r-20 {
  margin-right: 20px; }

html[dir="ltr"] body .m-l-20 {
  margin-left: 20px; }

html[dir="ltr"] body .p-t-20 {
  padding-top: 20px; }

html[dir="ltr"] body .p-b-20 {
  padding-bottom: 20px; }

html[dir="ltr"] body .p-r-20 {
  padding-right: 20px; }

html[dir="ltr"] body .p-l-20 {
  padding-left: 20px; }

html[dir="ltr"] body .p-20 {
  padding: 20px; }

html[dir="ltr"] body .m-20 {
  margin: 20px; }

html[dir="ltr"] body .m-t-25 {
  margin-top: 25px; }

html[dir="ltr"] body .m-b-25 {
  margin-bottom: 25px; }

html[dir="ltr"] body .m-r-25 {
  margin-right: 25px; }

html[dir="ltr"] body .m-l-25 {
  margin-left: 25px; }

html[dir="ltr"] body .p-t-25 {
  padding-top: 25px; }

html[dir="ltr"] body .p-b-25 {
  padding-bottom: 25px; }

html[dir="ltr"] body .p-r-25 {
  padding-right: 25px; }

html[dir="ltr"] body .p-l-25 {
  padding-left: 25px; }

html[dir="ltr"] body .p-25 {
  padding: 25px; }

html[dir="ltr"] body .m-25 {
  margin: 25px; }

html[dir="ltr"] body .m-t-30 {
  margin-top: 30px; }

html[dir="ltr"] body .m-b-30 {
  margin-bottom: 30px; }

html[dir="ltr"] body .m-r-30 {
  margin-right: 30px; }

html[dir="ltr"] body .m-l-30 {
  margin-left: 30px; }

html[dir="ltr"] body .p-t-30 {
  padding-top: 30px; }

html[dir="ltr"] body .p-b-30 {
  padding-bottom: 30px; }

html[dir="ltr"] body .p-r-30 {
  padding-right: 30px; }

html[dir="ltr"] body .p-l-30 {
  padding-left: 30px; }

html[dir="ltr"] body .p-30 {
  padding: 30px; }

html[dir="ltr"] body .m-30 {
  margin: 30px; }

html[dir="ltr"] body .m-t-40 {
  margin-top: 40px; }

html[dir="ltr"] body .m-b-40 {
  margin-bottom: 40px; }

html[dir="ltr"] body .m-r-40 {
  margin-right: 40px; }

html[dir="ltr"] body .m-l-40 {
  margin-left: 40px; }

html[dir="ltr"] body .p-t-40 {
  padding-top: 40px; }

html[dir="ltr"] body .p-b-40 {
  padding-bottom: 40px; }

html[dir="ltr"] body .p-r-40 {
  padding-right: 40px; }

html[dir="ltr"] body .p-l-40 {
  padding-left: 40px; }

html[dir="ltr"] body .p-40 {
  padding: 40px; }

html[dir="ltr"] body .m-40 {
  margin: 40px; }

/** * Zero */
html[dir="ltr"] body .m-0-0 {
  margin: 0; }

html[dir="ltr"] body .m-t-0 {
  margin-top: 0; }

html[dir="ltr"] body .m-r-0 {
  margin-right: 0; }

html[dir="ltr"] body .m-b-0 {
  margin-bottom: 0; }

html[dir="ltr"] body .m-l-0 {
  margin-left: 0; }

html[dir="ltr"] body .p-0-0 {
  padding: 0; }

html[dir="ltr"] body .p-t-0 {
  padding-top: 0; }

html[dir="ltr"] body .p-r-0 {
  padding-right: 0; }

html[dir="ltr"] body .p-b-0 {
  padding-bottom: 0; }

html[dir="ltr"] body .p-l-0 {
  padding-left: 0; }

// ---------------
.rounded-4 {
  border-radius: 4px !important;
}
